import React, { useState } from "react";
import Intro from "../components/Intro";
import Footer from "../components/Footer";
import MainGate from "../components/MainGate";
const UserHome = () => {
  
  return (
    <>

      <Intro/>
      <MainGate/>
     <Footer/>
    </>
  )
}

export default UserHome;
